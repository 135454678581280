import MVT from 'ol/format/MVT.js';
import Map from 'ol/Map.js';
import VectorTileLayer from 'ol/layer/VectorTile.js';
import VectorTileSource from 'ol/source/VectorTile.js';
import View from 'ol/View.js';
import { Fill, Icon, Stroke, Style, Text } from 'ol/style.js';

// const key =
//   'Your Mapbox access token from https://mapbox.com/ here';

const map = new Map({
  layers: [
    new VectorTileLayer({
      declutter: true,
      source: new VectorTileSource({
        attributions:
          '© <a href="https://www.mapbox.com/map-feedback/">Mapbox</a> ' +
          '© <a href="https://www.openstreetmap.org/copyright">' +
          'OpenStreetMap contributors</a>',
        format: new MVT({ layerName: "any" }),
        // url:
        //   'https://{a-d}.tiles.mapbox.com/v4/mapbox.mapbox-streets-v6/' +
        //   '{z}/{x}/{y}.vector.pbf?access_token=' +
        //   key,
        // url: 'http://localhost:8080/api/v1/visualization/tiles/main/{z}/{x}/{y}'
        url: 'https://geo.malevich.ai/api/v1/visualization/tiles/main/{z}/{x}/{y}'
      }),
      style: new Style({
        fill: new Fill({
          color: 'red'
        }),
        stroke: new Stroke({
          color: 'white'
        })
      }),
      // style: createMapboxStreetsV6Style(Style, Fill, Stroke, Icon, Text),  // FIXME set it
    }),
  ],
  target: 'map',
  view: new View({
    // center: [1284793.9, 6130594.6], // https://epsg.io/transform; lon 11.5415 lat 48.1425
    center: [3376876.8, 8385280.5], // https://epsg.io/transform; lon 30.335 lat 59.935
    zoom: 16,
  }),
});
